import { createGlobalStore } from 'hox';
import { useEffect, useMemo, useState } from 'react';
import store from 'store';
import { queryUserBaseInfo, queryCustomSetting, getPermission } from '@api/user';
import { useMemoizedFn } from 'ahooks';
import { queryDateFormatTypes } from '@api/enums';
import { GENERATION_SETTING_CODE } from './constant';

// 用户信息勾子
export const [useUserBaseInfo, getUseUserBaseInfo] = createGlobalStore(() => {
    const [baseUserInfo, setBaseUserInfo] = useState();
    const baseUserSetting = useMemo(() => ({}), []);

    const defaultDateFormat = baseUserSetting.dateFormatStr || 'YYYY-MM-DD';

    /**
     * 推荐使用 getBaseUserInfo 代替 baseUserInfo
     * 原因是 getBaseUserInfo 在调用时请求 baseUserInfo
     */
    const getBaseUserInfo = useMemoizedFn(async () => {
        const token = store.get('token');

        if (token) {
            const [{ body: base }, { body: customSettingList }, { body: dateFormatTypes }] = await Promise.all([
                queryUserBaseInfo.memo(token),
                queryCustomSetting.memo(), // 用户自定义设置列表
                queryDateFormatTypes.memo() // 日期格式枚举
            ]);

            setBaseUserInfo(base);
            // 找到用户设置的项
            customSettingList.some(({ businessId, businessBodys }) => {
                if (businessId === GENERATION_SETTING_CODE) {
                    const { dateFormat } = businessBodys;
                    const userSetting = { ...businessBodys };
                    // 日期格式
                    const { v: dateFormatStr } = dateFormatTypes.find(item => item.k === dateFormat) || {};

                    store.set('dateFormatStr', dateFormatStr);

                    if (dateFormatStr) {
                        Object.assign(userSetting, { dateFormatStr });
                    }
                    Object.assign(baseUserSetting, userSetting);
                    return true;
                }
                return false;
            });
        }
        // return baseUserInfo;
    });

    /**
     * 返回权限
     */
    const getUserPermission = useMemoizedFn(async () => {
        const [{ body: userPermission }] = await Promise.all([getPermission()]);

        store.set('permission', userPermission);
    });

    const defaultDateFormatBydateType = useMemoizedFn(dateType => {
        let dateFormatStrSlit = '';
        const arr = defaultDateFormat.split('.');
        const index = arr.findIndex(item => item === 'DD');

        arr.splice(index, 1);
        const MothFormatStr = arr.join('.');

        switch (dateType) {
            case 'date':
                dateFormatStrSlit = defaultDateFormat;
                break;
            case 'month':
                dateFormatStrSlit = MothFormatStr;
                break;
            case 'year':
                dateFormatStrSlit = 'YYYY';
                break;
            default:
                dateFormatStrSlit = '';
        }
        return dateFormatStrSlit;
    });

    const updateBaseUserInfo = useMemoizedFn(userInfo => {
        setBaseUserInfo(userInfo);
        // 清除缓存
        queryUserBaseInfo.clearMemo();
    });

    useEffect(() => {
        if (getBaseUserInfo && store.get('token')) {
            getBaseUserInfo();
        }

        if (!store.get('toke')) {
            setBaseUserInfo({});
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getBaseUserInfo, store.get('token')]);

    // useEffect(() => {
    //     if (baseUserInfo) {
    //         store.set('baseUserInfo', baseUserInfo);
    //     } else {
    //         store.remove('baseUserInfo');
    //     }
    // }, [baseUserInfo]);

    return {
        defaultDateFormat,
        baseUserInfo,
        setBaseUserInfo,
        getUserPermission,
        getBaseUserInfo,
        defaultDateFormatBydateType,
        updateBaseUserInfo,
        baseUserSetting
    };
});

export default {
    useUserBaseInfo,
    getUseUserBaseInfo
};
