import { TQ_Post, TQ_Get } from '@admin/utils/tools/axios';
import { memoryCacheWrap } from '@utils/width-memory-cache';
import { PAGE_SIZE } from '@utils/constant';

// 获取用户权限
// 获取用户权限，不可用权限和只读权限
export const getPermission = () => TQ_Get('/pc/user/getPermission');

// 用户基本信息
export const queryUserBaseInfo = memoryCacheWrap(token =>
    TQ_Get('/pc/user/userdetail', {
        _: Date.now(),
        token
    })
);

// 修改账号（其实就是修改邮箱地址）
export const updateAccount = ({ oldAccount, account, code, password }) =>
    TQ_Post('/pc/user/modify/account', {
        oldAccount,
        account,
        mailCode: code,
        password
    });

// 修改密码
export const modifyPassword = ({ newPassword, currentPassword, confirmPassword }) =>
    TQ_Post('/pc/user/modify/pwd', {
        newPassword,
        oldPassword: currentPassword,
        repeatPassword: confirmPassword
    });

// 重置密码
export const resetPassword = ({ account, mailCode, password, repeatPassword }) =>
    TQ_Post('/pc/user/resetpwd', {
        account,
        mailCode,
        password,
        repeatPassword
    });

// 注销账号
export const logOffAccount = ({ code, password }) =>
    TQ_Get('/pc/user/delete', {
        _: Date.now(),
        mailCode: code,
        password
    });

// 更新账户头像
export const updateAccountAvatar = accountImage => TQ_Post('/pc/user/modify/accountImage', {}, { accountImage });

// 更新用户名
export const updateAccountName = accountName =>
    TQ_Get('/pc/user/modify/accountName', {
        accountName
    });

// 服务站点
export const queryStationTypeOptions = memoryCacheWrap(() => TQ_Get('/pc/user/server/stations', { _: Date.now() }));

// 校验 token
export const validToken = token => TQ_Get(`/pc/user/valid/${token}`);

// 保存自定义数据
export const saveCustomSetting = (businessBodys, businessId) =>
    TQ_Post('/pc/user/custom/setting', {
        businessBodys,
        businessId
    });

// 查询自定义数据
export const queryCustomSetting = memoryCacheWrap(() => TQ_Get('/pc/user/custom/setting', { _: Date.now() }));

// 意见反馈
export const saveFeedback = ({ desc, comment, imagesUrls, telEmail }) =>
    TQ_Post('/pc/user/feedback', {
        desc,
        comment,
        imagesUrls,
        telEmail
    });

/**
 * 意见反馈列表
 */
export const queryFeedbackList = memoryCacheWrap(
    ({ endTime, startTime, account, operator, status, sortField, sortRule = 'DESC', pageNo = 1, pageSize = PAGE_SIZE }) =>
        TQ_Post('/pc/user/feedback/query', {
            endTime,
            startTime,
            operator,
            account,
            status: status || 'ALL',
            sortField,
            sortRule,
            pageNo,
            pageSize
        })
);

// 处理意见反馈 ---- 厂商才有权限
export const handleFeedback = ({ comment, feedBackId }) =>
    TQ_Get('/pc/user/feedback/hadnle', {
        comment,
        feedBackId
    });

/**
 * 重置用户密码
 */
export const resetAccountPwd = account => TQ_Post(`/pc/user/defaultpwd/${account}`);

/**
 * 根据业主用户名/或者邮箱查询
 */
export const getByUsernameOrEmail = ({ pageNo, pageSize, usernameOrEmail }) =>
    TQ_Get(`/pc/user/owner/getByUsernameOrEmail`, { pageNo, pageSize, usernameOrEmail });

/**
 * 删除业主
 */
export const deleteOwner = data => TQ_Post(`/pc/user/owner/delete`, data);

/**
 * 重置业主密码
 */
export const resetOwnerPwd = params => TQ_Post(`/pc/user/owner/resetPassword`, {}, params);

/**
 * 修改业主邮箱
 */
export const updateEmail = data => TQ_Post(`/pc/user/owner/updateEmail`, data);

/**
 * 查询被分享电站列表
 * @param params
 * @return {Promise | Promise<unknown>}
 */
export const getOwnerQuiltShareStation = params => TQ_Get(`/pc/user/owner/getOwnerQuiltShareStation`, params);

/**
 * 解除电站与被分享者的绑定关系
 * @param data
 * @return {Promise | Promise<unknown>}
 */
export const deleteStationShare = data => TQ_Post(`/station/share/deleteStationShare`, data);

/**
 * 免责声明是否不再显示
 * @return {Promise | Promise<unknown>}
 */
export const statementDeny = () => TQ_Post(`/pc/user/modify/statementDeny`);
