import { TQ_Get } from '@admin/utils/tools/axios';
import { memoryCacheWrap } from '@utils/width-memory-cache';

// 日期类型
export const queryDateFormatTypes = memoryCacheWrap(() => TQ_Get('/common/dateFormat/type', { _: Date.now() }));

// 设备类型
export const queryDeviceTypes = memoryCacheWrap(() => TQ_Get('/common/device/type', { _: Date.now() }));

// 角色类型
export const queryAccountTypes = memoryCacheWrap(() => TQ_Get('/common/organization/account/type'), { _: Date.now() });

// 国家列表
export const queryCountries = memoryCacheWrap(() => TQ_Get('/common/station/countries', { _: Date.now() }));

// 货币类型
export const queryCurrency = memoryCacheWrap(() => TQ_Get('/common/station/currency', { _: Date.now() }));

// 电站并网类型
export const queryJoinTypes = memoryCacheWrap(() => TQ_Get('/common/station/join/type', { _: Date.now() }));

// 时区列表
export const queryTimeZones = memoryCacheWrap(() => TQ_Get('/common/station/timeZones', { _: Date.now() }));

// 电站类型
export const queryStationTypes = () => TQ_Get('/common/station/type', { _: Date.now() });

// 通用设置里的「语言」
export const queryLanguageTypes = memoryCacheWrap(() => TQ_Get('/common/language/type', { _: Date.now() }));

// 根据传入的国家id，返回默认的时区等配置
export const defaultCountry = memoryCacheWrap(countryId => TQ_Get(`/pc/user/country/default/${countryId}`, { _: Date.now() }));
